/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useState } from "react";
import handIcon from "../../assets/images/hand-icon.png";
import FormInput from "src/components/FormInput";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { emailValidation } from "src/utils";
import { toast } from "react-toastify";
import { changePassword, login } from "src/api/user";
import { RootState } from "src/store";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { user, message, error, loading } = useAppSelector(
    (state: RootState) => state.user
  );
console.log({ user, message, error, loading })
  useEffect(() => {
    if (error) {
      toast.error(message);
    }

  }, [user, error, message, loading]);

  const _doChangePassword = () => {
    if (email?.trim().length > 0) {
      if (!emailValidation(email)) {
        toast.warning("Please enter valid email.");
        return;
      }
    } else if (email?.trim().length == 0) {
      toast.warning("Please enter email.");
      return;
    }
    if (password?.trim()?.length == 0) {
      toast.warning("Please enter password ");
      return;
    } else if (password?.trim()?.length < 5) {
      toast.warning("Password must be atleast 5 character long. ");
      return;
    }

    if (confirmPassword !== password) {
      toast.warning("New Password and Confirm Password must be same");
      return;
    }

    dispatch(changePassword({ email, password }))
      .then((res: any) => {
        if (res?.payload?.statusCode === 200) {
          toast.success(res?.payload?.message);
          setEmail("")
          setConfirmPassword("")
          setPassword("")
          navigate('/')
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <div>
      <div className="min-h-screen flex flex-col md:flex-row ">
        <a className="absolute top-3 left-5">
          <img
            className="w-40 hidden md:block"
            src="/images/final_logo_1.png"
          />
          <img
            className="w-36 block md:hidden"
            src="/images/final_logo_1.png"
          />
        </a>
        <div className="flex-1 hidden md:block bg-cover bg-no-repeat bg-forgotpassword border-r-2 br-pos">
          <div
            className="w-full text-xs md:text-sm bg-opacity-10 text-justify  py-3 px-10 max-w-xl text-center mx-auto mt-20 rounded-2xl text-[#1F7FBB] "
            id="border-red1"
          >
            Forensic accounting is a specialized field that combines accounting,
            auditing, and investigative skills to examine financial crimes and
            provide legal evidence.
          </div>
          <div className="upperpolygon"></div>
          <div className="downpolygon"></div>
        </div>
        <div className="flex-1 xl:gradient-pos flex items-center content-center justify-center ">
          <div className="max-w-xl mx-auto w-full px-5 py-15" id="formReg">
            <div className="">
              <div className="w-full flex flex-col mb-1 ">
                <div className="w-full flex items-center mb-1 justify-center">
                  <h2 className="flex font-medium text-2xl sm:text-2xl flex-1  ">
                    Have you forgotten the password?{" "}
                    <img
                      className="h-8 w-8 mt-1 "
                      src={handIcon}
                      alt="bot image"
                    />
                  </h2>
                </div>
                <p className="text-gray-600 mb-8">
                  Enter your register number to reset the password.
                </p>
                <div className="mb-1">
                  <div className="flex-1 w-full">
                    <label
                      htmlFor="email"
                      className="text-black block mb-2 font-bold"
                    >
                      Email*
                    </label>
                    <FormInput
                      required
                      value={email}
                      type="text"
                      placeholder="Enter Your Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-1 mt-3">
                  <div className="flex-1 w-full relative">
                    <label
                      htmlFor="Password"
                      className="text-black block mb-2 font-bold"
                    >
                      New Password*
                    </label>
                    <FormInput
                      required
                      type="password"
                      placeholder="Enter Your Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-1 mt-3">
                  <div className="flex-1 w-full relative">
                    <label
                      htmlFor="Password"
                      className="text-black block mb-2 font-bold"
                    >
                      Confirm Password*
                    </label>
                    <FormInput
                      required
                      type="password"
                      placeholder="Enter Your Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex  mb-3 w-full flex-col lg:flex-row md:justify-between mt-3">
                  <div className="flex flex-row">
                    <div className="font-light flex-none text-sm tracking-normal">
                      Do have an account already?
                    </div>
                    <a
                      onClick={() => navigate("/")}
                      className="font-bold flex-none text-blue-500 text-sm ml-2 hover:underline transition tracking-normal hover:cursor-pointer"
                    >
                      Sign In
                    </a>
                  </div>
                  {/* <a href="#" className="font-bold flex-none text-blue-500 text-sm  hover:underline transition tracking-normal">Forgot Password?</a> */}
                </div>
                <button
                  onClick={_doChangePassword}
                  className="font-bold text-lg mb-3 py-3 border-b-4 border border-blue-500 bg-blue-100 text-black block w-full rounded-lg hover:bg-blue-500 hover:text-white transition"
                  id="flowBtn"
                >
                  Reset
                </button>
                {/* <div className="text-xs font-light text-gray-500 tracking-normal">By creating an account you accept the <a className="font-bold text-blue-500 hover:underline transition" > Terms of Use </a> and <a className="font-bold text-blue-500 hover:underline transition"> Privacy Policy</a></div> */}
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
