import React from 'react'
const InlineLoader = () => {
  return (
    <div className='inline_loader_outer'>
        <div className='inline_loader'>
        </div>
    </div>
  )
}

export default InlineLoader
