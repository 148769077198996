/* eslint-disable jsx-a11y/alt-text */
import { useCallback } from "react";
import { Interview_Tabs } from "../../utils/constant";
import {cx} from 'class-variance-authority';
import TalkBot from '../../assets/images/Bot_grey.png'
import MessageBot from "../../assets/images/Message_Bot_grey.png";
import OnlineSupport from "../../assets/images/Online_Support_grey.png"
import TalkBotActive from '../../assets/images/Bot.png'
import MessageBotActive from "../../assets/images/Message_Bot.png"
import OnlineSupportActive from "../../assets/images/Online_Support.png"
import Questionaire from "../../assets/images/questionnaire.png"
import QuestionaireActive from "../../assets/images/questionnaire_ative.png"
interface Props {
  setActiveTab: (e?: string) => void
  activeTab:any
}
const Tabs = ({activeTab,setActiveTab}: Props) => {
  const handleTab = useCallback((step: string) => {
    setActiveTab(step);
  }, []);

const imgSrc={
 "Talk with a Bot":{src:TalkBot,srcActive:TalkBotActive},
 "Chat with a Bot":{src:MessageBot ,srcActive:MessageBotActive}, 
 "Talk to Our Accountant":{src:OnlineSupport,srcActive:OnlineSupportActive},
 "Fill Out The Questionnaire":{src:Questionaire,srcActive:QuestionaireActive}
}  
 
  return (
    <div className="flex flex-col">
      <h2 className="text-[#009BDB] font-poppins mb-4 text-center  font-semibold h-auto">What would be your preferred mode of communication to provide the information?</h2>
    <div className="bg-[#EEEEEE] h-auto rounded-xl m-auto w-[70%]">
      <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 gap-2 ">
        {Object.values(Interview_Tabs).map((item, index: number) => {
          return (
            <div
              key={index}
              onClick={()=>handleTab(item)}
              className={cx("flex flex-col  text-sm items-center justify-center basis-1/3 ml-1 mr-1 cursor-pointer rounded-xl p-3 h-14 whitespace-nowrap font-poppins hover:bg-[#009BDB] hover:text-white hover:font-semibold focus:bg-[#009BDB]"
                ,activeTab===item &&"bg-[#009BDB] text-sm font-semibold text-white"
              )}
            > 
           <img key={index} src={item === activeTab?imgSrc[item].srcActive:imgSrc[item].src}
             width={"20px"}
             height={"20px"}
           />
            {item}
            </div>
          );
        })}
      </div>
    </div>
    </div>
  );
};
export default Tabs;
