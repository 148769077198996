import React, { useEffect, useState } from "react";
import botImg from "../../assets/images/bot-icon.png";
import arrow from "../../assets/images/arrow-down.gif";
import handIcon from "../../assets/images/hand-icon.png";
import { Button } from "@material-tailwind/react";

import "../../css/chatbot.css";
import {
  ArrowRightIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import Privacy from "../../assets/images/privacy.png";
import FormInput from "../../components/FormInput";
import { emailValidation } from "src/utils";

interface PropsType {
  onClick: () => void;
  disableButton:boolean
  siteData:urlDecodedDataProps
}
export default function AwsWelcome({ onClick ,disableButton,siteData}: PropsType) {
  const [isContinue, setIsCoutinue] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isError, setError] = useState<boolean>(true);


  useEffect(()=>{
if(siteData){
  setEmail(siteData.email)
  setName(siteData.name)
}

  },[siteData])

  useEffect(()=>{
    console.log(emailValidation(email))
if(name&& email && name?.trim() && email?.trim()){
  if(!emailValidation(email)){
    setError(true)
  }else{
  setError(false)
  }
}else{
  setError(true)
}
  },[name,email])

  const _checkUserInfo=()=>{
if(email && name){
  onClick()
}
  }

  
  return (
    <div className="border-[#DDD] w-full rounded-sm pb-5 h-[75vh] mt-6 ">
      <div className="flex w-full h-full ">
        <div className="w-1/2 justify-center content-center hidden lg:block md:block">
          <img
            className="h-3/4 w-3/4 object-cover object-center self-center m-auto"
            src={botImg}
            alt="bot image"
          />
        </div>

        {isContinue ? (
          <div className=" bg-[#EDEDED] h-[80vh] lg:w-1/2 md:w-1/2 sm:w-[800px] p-4 pl-7  ">
            <p className="text-[#155278] text-2xl mb-16 pt-10">
              Fill Your Information
            </p>
            <FormInput
              icon={<UserIcon />}
              label="Name"
              value={name}
              required
              onChange={(e: any) => setName(e.target.value)}
              className="mb bg-white"
            />

            <div className="h-5" />

            <FormInput
              icon={<EnvelopeIcon />}
              className="bg-white"
              value={email}
              label="Email"
              required
              onChange={(e: any) => setEmail(e.target.value)}
            />

            <Button
              onClick={_checkUserInfo}
              disabled={isError}
              style={{
                flex: 1,
                backgroundColor: "#207FBB",
                color: "#FFF",
                marginTop: 60,
                paddingLeft: 40,
                paddingRight: 40,
              }}
              className=" flex items-center gap-3 text-[14px]  "
            >
              Continue
              <ArrowRightIcon color="#FFF" width={25} height={25} />
            </Button>

            <hr className=" border-[#A0A0A0] mt-8 " />
            <div className="flex mt-3 items-center">
              <div className="w-12  h-12 border-[#207FBB] border-[1px] rounded-full flex justify-center items-center bg-white z-20">
                <img src={Privacy} className="h-8 w-8" />
              </div>
              <div className="border-[#207FBB] border-[1.5px] rounded-xl border-l-0 text-xs items-center text-center p-1 -ml-2 pl-4 rounded-l-none bg-[#C3DEEF]">
                <p className="text-[#207FBB] font-semibold text-xs">
                  We ensures the privacy of your data at all times.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="welcome-info bg-[#EDEDED]  lg:w-1/2 md:w-1/2 sm:w-full p-4 pl-7 sm:!text-[14px] lg:!text-[18px] md:!text-[18px] ">
            <span className="flex  items-center pt-12 ">
              <span> Hello! &nbsp; Meet Eli,</span>
              <img className="h-8 w-8 mt-1 " src={handIcon} alt="bot image" />
            </span>
            <span className="">
           
           Eli is here to explore your claim with a few quick questions. Share as much detail as you’re comfortable with, and we’ll provide tailored support. Ready to start? Let’s go!
            </span>
            <img
              className="h-1/5 w-1/6 object-cover object-center self-center m-auto mt-10 mb-12"
              src={arrow}
              alt="bot image"
            />
            <Button
              onClick={() => setIsCoutinue(true)}
              // disabled={disableButton}
              style={{
                flex: 1,
                backgroundColor: "#207FBB",
                color: "#FFF",
                // width: 100,
                paddingLeft: 40,
                paddingRight: 40,
              }}
              className="flex items-center m-auto text-[14px]"
            >
              CHAT
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
