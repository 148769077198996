/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { ReviewSection } from "./ReviewSection";
import { cx } from "class-variance-authority";
import FormInput from "src/components/FormInput";
import DateTimePicker from "src/components/DateTimePicker";
import { AnwserSet } from "./anwserSet";
import { Radio } from "@material-tailwind/react";
import { Checkbox } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { saveAnswer } from "src/api/questionair";
import Loader from "src/components/loader";
import { toast } from "react-toastify";
import { chunkArray } from '../../utils/index'
interface props {
  activeStep: number;
  questionsRes: any;
  categoryRes: any;
  siteData: any;
  activeStepName: string;
  handleNext: () => void;
  handleBack: () => void;
  handleReset: () => void;
}
const InterviewForm = ({
  activeStep,
  handleNext,
  handleBack,
  handleReset,
  questionsRes,
  categoryRes,
  activeStepName,
  siteData,
}: props) => {
  const [anwserSet, setAnwserSet] = useState<Array<AnwserSet>>([]);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const dispatch = useDispatch<AppDispatch>();
  const { error, loading, message, saveAnswerRes } = useSelector(
    (state: RootState) => state.questionnair
  );

  const getRadioOptions = (optionItem: any) => {
    return optionItem.map((ele: any) => { return { option: ele.option, sortOrder: ele.sortOrder } });
  };

  const getMultiChipOptions = (optionItem: any) => {
    return chunkArray(optionItem.map((ele: any) => { return { option: ele.option, sortOrder: ele.sortOrder } }).sort((a: any, b: any) => a.sortOrder - b.sortOrder),6);
  
  };

  const getMultiChoice = (optionItem: any) => {
    return optionItem.map((ele: any) => { return { option: ele.option, sortOrder: ele.sortOrder } });
  };

  const handleValidation = (element: any, value: any) => {
    const errorMessage = validateAnswer(element, value);
    setValidationErrors((prev) => {
      const updatedErrors = { ...prev, [element.id]: errorMessage };
      return updatedErrors;
    });

    if (element.required && (value === "" || value === undefined || value === null)) {
      return "This field is required.";
    }
    if (element.required && (value?.length===0)) {
      return "Please select ateast one option";
    }
    return errorMessage;
  };

  const handleChange = (e: any, id: any) => {
    let anwSet = [...anwserSet];
    let answer = {
      questionId: id,
      free_form_text: e,
    };
    const index = anwSet.findIndex((obj) => obj.questionId === id);
    if (index !== -1) {
      anwSet[index] = answer;
    } else {
      anwSet.push(answer);
    }
    setAnwserSet(anwSet);
    handleValidation({ id, validationType: 'STRING' }, e);
  };

  const handleChangeTextarea = (e: any, id: any) => {
    let anwSet = [...anwserSet];
    let answer = {
      questionId: id,
      free_for_large_text: e.target.value,
    };
    const index = anwSet.findIndex((obj) => obj.questionId === id);
    if (index !== -1) {
      anwSet[index] = answer;
    } else {
      anwSet.push(answer);
    }
    setAnwserSet(anwSet);
    handleValidation({ id, validationType: 'TEXTAREA' }, e.target.value);
  };

  const handleOnclickk = (value: any, id: any) => {
    let anwSet = [...anwserSet];
    let answer = {
      questionId: id,
      multi_chip_selection_text: value,
    };
    const index = anwSet.findIndex(
      (obj) => obj.questionId === id && obj.multi_chip_selection_text === value
    );
    if (index !== -1) {
      anwSet.splice(index, 1);
    } else {
      anwSet.push(answer);
    }
    setAnwserSet(anwSet);

    handleValidation(
      { id, validationType: 'MULTI_CHIP_SELECTION' },
      anwSet.filter(itemm => itemm.questionId === id).map(itemm => itemm.multi_chip_selection_text)
    );
  };

  useEffect(() => {
    if (questionsRes && siteData && anwserSet.length == 0) {
      let businessName: any = [];
      let businessAddress: any = [];
      let email: any = [];
      let phoneNumber: any = [];
      questionsRes.forEach((item: any) => {
        if (item.question === "Business Name") {
          businessName = [
            {
              questionId: item.id,
              free_form_text: siteData.buisnessName,
            },
          ];
        }
        if (item.question === "Business Address") {
          businessAddress = [
            {
              questionId: item.id,
              free_form_text: siteData.businessAddress,
            },
          ];
        }
        if (item.question === "Email Address") {
          email = [
            {
              questionId: item.id,
              free_form_text: siteData.email,
            },
          ];
        }
        if (item.question === "Phone Number") {
          phoneNumber = [
            {
              questionId: item.id,
              free_form_text: siteData.phoneNumber,
            },
          ];
        }
        setAnwserSet([...businessName, ...businessAddress, ...email, ...phoneNumber]);
      });
    }
  }, [siteData, questionsRes]);

  const onRadioChange = (e: any, id: any) => {
    let anwSet = [...anwserSet];
    let answer = {
      questionId: id,
      mcq_text: e,
    };
    const index = anwSet.findIndex((obj) => obj.questionId === id);
    if (index !== -1) {
      anwSet[index] = answer;
    } else {
      anwSet.push(answer);
    }
    setAnwserSet(anwSet);
    handleValidation({ id, validationType: 'RADIO' }, e);
  };

  const handleDateRange = (e: any, id: any) => {
    let anwSet = [...anwserSet];
    let answer = {
      questionId: id,
      date_text: e,
    };
    const index = anwSet.findIndex((obj) => obj.questionId === id);
    if (index !== -1) {
      anwSet[index] = answer;
    } else {
      anwSet.push(answer);
    }
    setAnwserSet(anwSet);
  };

  const validateAnswer = (element: any, value: any) => {

    switch (element.validationType) {
      case "STRING":
        if (!value || value.trim() === "") {
          return "This field is required.";
        }
        break;
      case "EMAIL":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || !emailRegex.test(value)) {
          return "Please enter a valid email address.";
        }
        break;
      case "PHONENUMBER":
        const phoneRegex = /^[0-9]{10}$/;
        if (!value || !phoneRegex.test(value)) {
          return "Please enter a valid phone number (10 digits).";
        }
        break;
      case "CHECKBOX":
        if (!value) {
          return "You must select at least one option.";
        }
        break;
      case "RADIO":
        if (!value) {
          return "You must select at least one option.";
        }
        break;
      case "TEXTAREA":
        if (!value || value.trim() === "") {
          return "This field is required.";
        }
        break;
      case "MULTI_CHOICE":
        if (value?.length === 0) {
          return "You must select at least one option.";
        }
        break;
      case "MULTI_CHIP_SELECTION":
        if (!Array.isArray(value) || value.length === 0) {
          return "You must select at least one option.";
        }
        break;

      default:
        return "";
    }
    return "";
  };

  const onFinalSubmit = () => {
    let hasError = false;
    const updatedErrors = { ...validationErrors };

    questionsRes.forEach((element: any) => {
      const answer = anwserSet.find((item) => item.questionId === element.id);
      let answerValue;

      if (element.validationType === 'TEXTAREA') {
        answerValue = answer?.free_for_large_text || "";
      } else if (element.validationType === 'CHECKBOX') {
        answerValue = anwserSet
          .filter((item) => item.questionId === element.id)
          .map((item) => item.multi_chip_selection_text);
      } else {
        answerValue = answer?.free_form_text || answer?.mcq_text || "";
      }
      const error = handleValidation(element, answerValue);
      if (error) hasError = true;
      updatedErrors[element.id] = error;
    });

    setValidationErrors(updatedErrors);

    if (hasError) {
      return;
    }

    const payload = {
      claimNumber: siteData.claimNumber,
      email: siteData.email,
      setId: 1,
      answers: anwserSet,
    };
    dispatch(saveAnswer(payload));
  };


  useEffect(() => {
    if (error && message) {
      toast.error(message, {
        position: "bottom-center",
      });
    }
  }, [error, message])

  useEffect(() => {
    if (saveAnswerRes?.statusCode == 201) {
      handleNext();
    }
  }, [saveAnswerRes])

  const matchType = (element: any) => {
    const errorMessage = validationErrors[element.id];
    const value = anwserSet?.find((item) => item.questionId === element.id);
    const disabledQuestions = [
      "Business Name",
      "Business Address",
      "Email Address",
      "Phone Number",
    ];
  
    const isDisabled = disabledQuestions.includes(element.question);

    switch (element.type) {
      case "FREEFORM":
        return (
          <div>
            <FormInput
              name={`answer-${element.id}`}
              type={element.validationType === "PHONENUMBER" ? "tel" : "text"}
              key={element.id}
              className={`mt-2 border-2 bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:ring-sky-900/10 ${isDisabled ? "!border-2 !border-[#CECECE] !border-t-[#CECECE]" : "border-[#CECECE] border-t-[#CECECE] bg-white"}`}
              value={value?.free_form_text || ""}
              onChange={(e) => {
                if (element.validationType === "PHONENUMBER") {
                  const newValue = e.target.value;
                  if (/^\d*$/.test(newValue) && newValue.length <= 10) {
                    handleChange(newValue, element.id);
                  }
                } else {
                  handleChange(e.target.value, element.id);
                }
              }}
              onBlur={(e) => handleValidation(element, e.target.value)}
              maxLength={element.validationType === "PHONENUMBER" ? 10 : 3000}
              disabled={isDisabled}
            />
            {errorMessage && <span className="text-red-500 text-xs mt-2 block">{errorMessage}</span>}
          </div>
        );
      case "MULTI_CHIP_SELECTION":
        return (

          <div className="mt-2">
            <div className="flex flex-wrap mt-1">
              <div className="">
                <div className="flex flex-col">
                  {getMultiChipOptions(element.options)
                  .map((row:any,rowIndex?:number) => {
                    return <div key={rowIndex} className="flex flex-row mt-2">
                    {row.map((item?: any, key?: number) => (
                      <div
                        key={key}
                        onClick={() => handleOnclickk(item.option, element.id)}
                        onBlur={() => handleValidation(element, anwserSet.filter(itemm => itemm.questionId === element.id).map(itemm => itemm.multi_chip_selection_text))}
                        className={`w-[40px] text-xs font-medium font-poppins h-[40px] rounded-full  border-2 cursor-pointer flex justify-center items-center mr-2 shadow-md hover:bg-[#0774D9] hover:text-white 
                        ${anwserSet?.filter(
                          (itemm) =>
                            itemm.questionId === element.id &&
                            itemm.multi_chip_selection_text === item.option
                        )?.length > 0
                            ? "bg-[#0774D9] text-white"
                            : "bg-[#fff] text-black"
                          }`}
                      >
                        {item.option.substring(0, 3)}
                      </div>
                    ))}
                    </div>
                    })}
                </div>
         
              </div>
            </div>
            {errorMessage && (
              <span className="text-red-500 text-xs mt-1">{errorMessage}</span>
            )}
          </div>
        );
      case "MCQ":
        return (
          <>
          <div>
            {getRadioOptions(element.options).sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((opt: any) => (
              <Radio
                label={opt.option}
                className="w-6 h-6"
                value={opt.option}
                name={`mcq-${element.id}`}
                checked={value?.mcq_text === opt.option}
                color={"blue"}
                onChange={() => onRadioChange(opt.option, element.id)}
                onBlur={() => handleValidation(element, value?.mcq_text)}
              />
            ))}
          </div>
            {errorMessage && <span className="text-red-500 text-xs mt-1">{errorMessage}</span>}
        </>
        );
      case "LARGE_FREE_FORM_TEXT":
        return (
          <div>
            <textarea
              id={"message" + element.id}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write your thoughts here..."
              value={value?.free_for_large_text || ""}
              onChange={(e) => handleChangeTextarea(e, element.id)}
              onBlur={(e) => handleValidation(element, e.target.value)}
            ></textarea>
            {errorMessage && <span className="text-red-500 text-xs mt-1">{errorMessage}</span>}
          </div>
        );
      case "DATE_RANGE":
        const minDateTime = element.validationType === "disablePastDates" ? new Date() : undefined;
        const maxDateTime = element.validationType === "disableFutureDates" ? new Date() : undefined;
        return (
          <div>
            <DateTimePicker
              value={value?.date_text || ""}
              onChange={(e) => handleDateRange(e, element.id)}
              onBlur={() => handleValidation(element, value?.date_text)}
              minDateTime={minDateTime}
              maxDateTime={maxDateTime}
            />{" "}
            {errorMessage && <span className="text-red-500 text-xs mt-1">{errorMessage}</span>}
          </div>
        );
      case "MULTI_CHOICE":
        return (
          <div>
            {getMultiChoice(element.options).sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((opt: any) => (
              <Checkbox
                id={opt.option + element.getClaimDetailsById}
                label={opt.option}
                value={opt.option}
                color={"blue"}
                checked={
                  anwserSet.filter(
                    (obj2) =>
                      element.id === obj2.questionId && opt.option === obj2.mcq_text
                  ).length > 0
                }
                className={`w-[1.25rem] mt-0`}
                name={opt}
                onChange={() => {
                  onRadioChange(opt.option, element.id);
                }}
                onBlur={() => handleValidation(element, anwserSet.filter(item => item.questionId === element.id).map(item => item.multi_choice_text))}
              />
            ))}
          </div>
        );
    }
  };


  const QuestionsSet = () => {
    const [isSeasonal, setIsSeasonal] = useState(false);

    useEffect(() => {
      const seasonalQuestion = questionsRes.find(
        (question: any) => question.question === "Are You Regular or Seasonal Business?"
      );
  
      const businessTypeAnswer = anwserSet.find(
        (answer: any) => answer.questionId === seasonalQuestion?.id && answer.mcq_text === "Seasonal"
      );
  
      setIsSeasonal(!!businessTypeAnswer);
    }, [anwserSet, questionsRes]);
    
    return (
      <>
        {loading && <Loader />}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 w-[95%] pt-2">
          {questionsRes?.map((element: any, index: any) => {

            if (element.question === "Months of Operation" && !isSeasonal) {
              return null;
            }
  
            return (
              activeStepName === element.category &&
              element.active && (
                <div key={element.id} className="flex flex-col">
                  {/* Display question number and required indicator */}
                  <span className="text-[14px] font-semibold">
                    {index + 1}. {element.question}
                    {element.required && <span className="text-red-500">*</span>}
                  </span>
  
                  {/* Render matchType based on question type, if it’s not "Months of Operation" or if it’s "Months of Operation" with Seasonal selected */}
                  {element.question !== "Months of Operation" || isSeasonal ? (
                    matchType(element)
                  ) : null}
  
                  {/* Render hierarchical questions based on selected options */}
                  <div>
                    {element?.options?.map((opt: any, key: number) => (
                      <div key={key}>
                        {/* Check if there’s an answer that matches the questionId and option */}
                        {anwserSet?.filter(
                          (obj2: any) => element.id === obj2.questionId && opt.option === obj2.mcq_text
                        ).length > 0
                          ? opt?.hierarchicalQuestions?.map((hr: any, indexx: number) => (
                              <React.Fragment key={indexx}>
                                <span className="text-[14px] font-semibold mb-1">
                                  {hr.question}
                                  {hr.required && <span className="text-red-500">*</span>}
                                </span>
                                {matchType(hr)}
                              </React.Fragment>
                            ))
                          : anwserSet?.filter(
                              (obj2: any) => element.id === obj2.questionId && opt.option === obj2.multi_choice_text
                            ).length > 0
                          ? opt?.hierarchicalQuestions?.map((hr: any, keyy: number) => (
                              <React.Fragment key={keyy}>
                                <span className="text-[14px] font-semibold mb-1">
                                  {hr.question}
                                </span>
                                {matchType(hr)}
                              </React.Fragment>
                            ))
                          : null}
                      </div>
                    ))}
                  </div>
                </div>
              )
            );
          })}
        </div>
      </>
    );
  };
  
  return (
    <>
      <div className="sticky top-0">
        <div className="flex justify-end text-sm">
          Claim Number: &nbsp;
          <span className="text-[#1F7FBB] font-semibold">#{siteData?.claimNumber}</span>
        </div>
        <div className="">{QuestionsSet()}</div>

        <div className="">
          {activeStep === categoryRes?.length && (
            <ReviewSection
              categoryRes={categoryRes}
              questionsRes={questionsRes}
              anwserSet={anwserSet}
            />
          )}
        </div>

        <div className="flex justify-end mr-12 bottom-0 mt-6 mb-2">
          {activeStep > 0 && (
            <label
              className="font-poppins text-base text-black-900 font-bold antialiased cursor-pointer mr-4 align-middle relative top-2"
              onClick={() => handleBack()}
            >
              Back
            </label>
          )}
          <CustomButton
            className={cx(
              "bg-[#1F7FBB]",
              "border-[#1F7FBB]",
              "justify-center",
              "shadow-md shadow-[#1F7FBB]",
              "w-[150px]"
            )}
            label={activeStep === categoryRes?.length ? "Submit" : "Next"}
            onClick={() => {
              activeStep === categoryRes?.length
                ? onFinalSubmit()
                : handleNext();
            }}
          />
        </div>
      </div>

    </>
  );
};

export default InterviewForm;
