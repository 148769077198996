import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uploadReport, generateOCR } from '../../api/documentApi';
interface propstate{
    loading:boolean
    error?:any
    data?:any,
    generateOCRLoading:boolean
    generateOCRError?:any
    generateOCRRes?:any
}
const initialState: propstate ={
    loading: false,
    error: null,
    data: null,
    generateOCRLoading:false,
    generateOCRError:null,
    generateOCRRes:null
}
const reportingSlice = createSlice({
    name: "Reporting",
    initialState,
    reducers: {
        resetReportingValue: (state) => {
            state.loading= false
            state.error= null
            state.data= null
        },
        resetGenerateOCRValue: (state) => {
            state.generateOCRLoading= false
            state.generateOCRError= null
            state.generateOCRRes= null
        
          }
    
    
    },
    extraReducers: (builder) => {
        builder
          .addCase(uploadReport.pending, (state) => {
            state.loading = true;
            state.data = "";
            state.error = false;
          })
          .addCase(uploadReport.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = false;
          })
          .addCase(uploadReport.rejected, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = true;
          })
          .addCase(generateOCR.pending, (state) => {
            state.generateOCRLoading = true;
            state.generateOCRRes = "";
            state.generateOCRError = false;
          })
          .addCase(generateOCR.fulfilled, (state, action) => {
            state.generateOCRLoading = false;
            state.generateOCRRes = action.payload;
            state.generateOCRError = false;
          })
          .addCase(generateOCR.rejected, (state, action) => {
            state.generateOCRLoading = false;
            state.generateOCRRes = action.payload;
            state.generateOCRError = true;
          })
        },
    });
    export const {
        resetReportingValue,resetGenerateOCRValue
    } = reportingSlice.actions;
    export default reportingSlice.reducer;