import moment from 'moment';
import React, { useState, useRef } from 'react';
import { DialogCustomAnimation } from 'src/components/dialogForChangeMode';
interface props{
  item:any
  selectedDate:any
  callDeleteSchedule:(id:any)=>void
  handleItemIdChange: (date: any,item?:any ) => void;
  setActiveTab:()=>void;
}
const CardWithMenu = ({item,callDeleteSchedule,handleItemIdChange,setActiveTab}:props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [open,setOpen]=useState(false)
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsMenuOpen(true);
  };

  const handleEdit = () => {
    handleItemIdChange({id:item?.id,dateTime:item?.scheduleDate,timeZone:item?.timezone},item)
    setIsMenuOpen(false); // Close menu after clicking
  };

  function handleDelete() {
    callDeleteSchedule(item?.id)
    setIsMenuOpen(false); // Close menu after clicking
  };

  // Close the menu if clicked outside
  // useEffect(() => {
  //   const handleClickOutside = (event:any) => {
  //       setIsMenuOpen(false);
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  return (
    <div className="relative flex items-center p-4 bg-white rounded-lg shadow-md max-w-sm">
      {/* Badge */}
      <div className="flex items-center justify-center w-12 h-12 bg-[#1F7FBB] rounded-l-lg text-white font-semibold">
        SC
      </div>
      
      {/* Content */}
      <div className="ml-4 flex-1">
        <h3 className="text-sm font-semibold text-gray-800 truncate"> {item.interviewType==="TALK_WITH_ACCOUNTANT"?"Schedule Call With Accountant":""}</h3>
        <p className="text-xs text-gray-500">{moment(item.scheduleDate).format("MM/DD/YYYY hh:mm a")}</p>
        <p className="text-xs text-gray-500">{item.timezone}</p>

      </div>

      {/* Actions Menu Icon */}
      <div className="ml-auto relative" ref={menuRef}>
        <button onClick={toggleMenu} className="text-gray-400 hover:text-gray-600 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 3a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm0 7a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm0 7a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" />
          </svg>
        </button>

        {/* Dropdown Menu */}
        {isMenuOpen && (
          <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
            <button
              onClick={handleEdit}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Change Interview Schedule
            </button>
            <button
              type="button"
              onClick={()=>setOpen(true)}
              className="block w-full text-left px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
            >
              Change Interview Mode
            </button>
          </div>
        )}
      </div>
      <DialogCustomAnimation setActiveTab={setActiveTab} open={open} handleOpen={()=>setOpen(!open)} title="Select Interview Mode"/>
    </div>
  );
};

export default CardWithMenu;
