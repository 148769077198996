import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./axiosInstance";
import axiosInstanceFileUpload from "./axiosFileupload";

export const downloadDocumets = createAsyncThunk("downloadDocumets", async (payload:GetFileProps, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/claim/getFile/${payload.fileId}/${payload.claimId}`);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  });


  export const getClaimDocumets = createAsyncThunk("getClaimDocumets", async (payload:GetFileProps, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/claim/getClaimsFile/${payload.claimId}`);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  });

  export const saveTranscriptDocumets = createAsyncThunk("saveTranscriptDocumets", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/claim/createTranscript`,payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  });

  
  export const uploadReport = createAsyncThunk("uploadReport", async (payload:any, { rejectWithValue }) => {
    try {
     console.log("payload",payload)
      const response = await axiosInstanceFileUpload.post(`/claim/uploadReport`,payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  });

  export const generateOCR = createAsyncThunk("generateOCR", async (payload:any, { rejectWithValue }) => {
    try {
     console.log("payload",payload)
      const response = await axiosInstance.post(`/claim/generateOCR/${payload.claimId}`,payload.id);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  });

  





