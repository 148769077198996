/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../css/chatbot.css";
import { MicrophoneIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import PopoverOption from "./PopoverOption";
import { Button } from "@mui/material";
import AwsWelcome from "./AwsWelcome";
import { RootState } from "src/store";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { nextQuestion, startQuestions, submitAnswer } from "src/api/chatbotApi";
import { resetChatBotData, setMessagesValue, setUserInputMessage } from "src/store/slices/chatBotSlice";
import InlineLoader from "src/components/loader/InlineLoader";

interface AwsChatbotProps {
  onSessionEnd: (lastMessage?: any) => void;
  siteData: urlDecodedDataProps;
  disableButton: boolean;
}
const AwsChatbot = ({
  onSessionEnd,
  siteData,
  disableButton,
}: AwsChatbotProps) => {
  const [input, setInput] = useState("");
  const [isActiveSession, setIsActiveSession] = useState<boolean>(false);
  const scrollToRef: any = useRef();
  const dispatch = useAppDispatch();
  const {chatbotMessage,loading} = useAppSelector((state: RootState) => state.chatbot);
  const callNextQuestion = (folderName:string) => {
    dispatch(nextQuestion({ user_id: folderName }));
  };
  useEffect(() => {
    if (chatbotMessage.length > 0) {
      setIsActiveSession(true)
      const filterData = chatbotMessage.filter(
        (item) => item.end_of_questions === true
      );
      if (filterData.length > 0) {
        setTimeout(() => {
          dispatch(setMessagesValue(null))
           localStorage.setItem("CHAT_BOT_DATA",JSON.stringify(""))
          onSessionEnd(filterData[0]);
        }, 1500);
      }
    localStorage.setItem("CHAT_BOT_DATA",JSON.stringify(chatbotMessage))
    }
    scrollToBottom();
  }, [JSON.stringify(chatbotMessage)]);

  

  const handleSendMessage = async (pInput?: string) => {
    const userInputMessage = input || pInput;
    if(!chatbotMessage) return
    const lastQuestionIndex=chatbotMessage?.length - 1
    const lastQuestion=chatbotMessage[lastQuestionIndex]
    const quesId = lastQuestion?.question_id;
    const option = lastQuestion?.options;
    if (!userInputMessage || !quesId) return;
    const userInput={
            user_id: siteData.claimNumber,
            question_id: quesId,
            answer: userInputMessage,
          }
    dispatch(setUserInputMessage(userInput))
    const oneDriveFolderName =`${siteData.buisnessName}-${siteData?.fileNumber}-${siteData?.claimNumber}`;
    dispatch(
      submitAnswer({
        user_id: oneDriveFolderName,
        question_id: quesId,
        answer: userInputMessage,
        expected_responses: option,
      })
    );
    setInput("");
  };

  const scrollToBottom = () => {
    if (document.getElementById("messagesBox")) {
      let element: any = document.getElementById("messagesBox");
      element.scrollTop = element.scrollHeight + 300;
    }
  };

  const _onStartChatbot = () => {
    setIsActiveSession(!isActiveSession);
    const oneDriveFolderName =`${siteData.buisnessName}-${siteData?.fileNumber}-${siteData?.claimNumber}`;
    dispatch(startQuestions({ user_id: oneDriveFolderName })).then(() => {
      callNextQuestion(oneDriveFolderName)
    });
  };

  if (!isActiveSession) {
    return (
      <AwsWelcome
        siteData={siteData}
        onClick={_onStartChatbot}
        disableButton={disableButton}
      />
    );
  }

  return (
    <div className="border-[#DDD] w-full border rounded-sm pb-5 h-[70vh] mt-3  ">
      <div className="chat-box h-[60vh]" id="messagesBox">
        {chatbotMessage.map((message: any, index: number) => (
          <div key={index + Math.random()} className="flex-1 ">
            {message?.question ? (
              <div className={`bot speech-bubble mt-2`}>
                {message?.options?.length > 0 ? null : (
                  <p>{message?.question}</p>
                )}
              </div>
            ) : (
              message?.answer && (
                <div className="flex justify-between mt-2">
                  <div />
                  <div
                    className={`${message?.answer} speech-bubble-right justify-end content-start self-end`}
                  >
                    <p>{message?.answer}</p>
                  </div>
                </div>
              )
            )}
            <div className="w-fit flex items-center">
              {message?.options?.length > 0 && (
                <div className="botactioncontainer speech-bubble">
                  <p className="button-container-title ">{message?.question}</p>
                  <div className="">
                    {message?.options?.map(
                      (buttonMetaData: any, index: number) => (
                        <button
                          key={index + "button"}
                          value={buttonMetaData}
                          className="capitalize"
                          onClick={() => {
                            handleSendMessage(buttonMetaData);
                          }}
                        >
                          {buttonMetaData}
                        </button>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        <div style={{}} ref={scrollToRef}></div>
      </div>
      {/* <div className="flex absolute z-40 bottom-[23vh] left-[35vh]">
        <EmojiPicker
          onReactionClick={onEmojiClick}
          open={isVisibleEmoji}
          onEmojiClick={onEmojiClick}
        />
      </div> */}

      <div className="flex mt-4  self-end justify-end ">
        <div className="flex justify-center w-12 h-12 bg-[#fff] rounded-full pt-2">
          {/* <Button>
            <FaceSmileIcon
              onClick={() => setIsVisibleEmoji(true)}
              className="w-7 h-7 self-center text-[#EDCB65]"
            />
          </Button> */}
          {loading&&
          <InlineLoader />
}
        </div>
        <div className="flex justify-center w-12 h-12  border-[1px]  rounded-full ">
          <PopoverOption />
        </div>
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
          disabled={
           (chatbotMessage?.length > 0 &&
            chatbotMessage[chatbotMessage.length - 1]?.options?.length > 0)
          }
          // style={{borderWidth:1,borderColor:'#DDD',height:20}}
          className="chatbot-action-input sm:w-full md:w-full w-[100%] lg:w-[50%] border-[#2c1717] mr-4 ml-2 p-2"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <div className="flex justify-center w-12 h-12  border-[2px]  rounded-full ">
          <Button>
            <MicrophoneIcon className="w-6 h-6 self-center" />
          </Button>
        </div>
        <Button
          disabled={loading}
          className="!bg-[#207FBB] !mr-6 !ml-3"
          onClick={() => handleSendMessage()}
        >
          
          <PaperAirplaneIcon width={20} height={20} color="#FFF" />
        </Button>
      </div>
    </div>
  );
};

export default AwsChatbot;
