import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../components/FormInput";
import { RootState } from "src/store";
import { setInsuredInfo } from "src/store/slices/claimSlice";
import { ClaimData } from "./ClaimData";
import { useCallback } from "react";
import { emailValidation, onlyAlphabet } from "src/utils";

type InsuredInfo = Pick<ClaimData, 'insuredFirstName'|'insuredLastName' | 'insuredTitle' | 'insuredBusinessName' | 'insuredPostalAddress' | 'insuredPhoneNumber' | 'insuredEmail'>;

const InsuredInformation: React.FC = () => {

    const dispatch = useDispatch();
    const insuredInfo = useSelector((state: RootState) => state.claim.insuredInfo);

    const claimInputs: { label: string; key: keyof InsuredInfo, required: boolean }[] = [
        { label: "Insured First Name", key: "insuredFirstName", required: true },
        { label: "Insured Last Name", key: "insuredLastName", required: true },
        { label: "Insured Title", key: "insuredTitle", required: false },
        { label: "Insured Business Name", key: "insuredBusinessName", required: true },
        { label: "Insured Business Address", key: "insuredPostalAddress", required: true },
        { label: "Insured Phone Number", key: "insuredPhoneNumber", required: true },
        { label: "Insured Email Address", key: "insuredEmail", required: true },
    ]

    const handleInputChange = useCallback(
        (key: keyof InsuredInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const reg=/^[0-9\b]+$/
            if(key ==="insuredPhoneNumber"){
             if(reg.test(value)||value==="")
                 dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
            }else if(key === "insuredFirstName" ||key === "insuredLastName"){
                if(onlyAlphabet(value)){
                  dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
                }
        
              }
            
            else{
             dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
            }

            // dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
        },
        [dispatch, insuredInfo]
    );

    return (
        <>
            <div className="mt-1">
                <span className="font-bold text-[#045373]">Insured Information</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3  gap-2">
                {claimInputs.map(({ label, key, required }) => (
                    <div key={label} className="flex flex-col ">
                        <FormInput
                            name={label}
                            label={label}
                            required={required}
                            type="text"
                            className="mt-1 h-[34px] border-2 border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent"
                            maxLength={key ==="insuredPhoneNumber" ?10:100}
                            value={insuredInfo?.[key] || ""}
                            onChange={handleInputChange(key)}
                        />
                         {key=="insuredEmail"&& !emailValidation(insuredInfo?.["insuredEmail"]) && <span className="text-red-700 text-xs"> Enter valid email </span>}

                    </div>
                ))}

            </div>
        </>
    );
};

export default InsuredInformation;