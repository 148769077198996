import { createSlice } from "@reduxjs/toolkit";
import { downloadDocumets, getClaimDocumets, saveTranscriptDocumets } from "src/api/documentApi";
import { changePassword, getUserById, login, signup } from "src/api/user";

interface propState {
  loading: boolean;
  error: boolean;
  message: any;
user:any
}

export const getLocalUser=()=>{
const res=  localStorage.getItem("UserInfo")
if(res){
  return JSON.stringify(JSON.stringify(res))
}

}
const initialState: propState = {
  loading: false,
  error: false,
  message: "",
  user:getLocalUser()
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.message=""
      state.user=null;
  
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.message=""
        state.error=false
      })
      .addCase(login.fulfilled, (state, action:any) => {
        state.user = action.payload?.data;
        state.loading = false;
        state.message=action.payload?.message
        state.error=false
      })
      .addCase(login.rejected, (state, action:any) => {
        state.loading = true;
        state.user = null;
        state.message=action.payload?.message
        state.error=true
      })
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.message=""
        state.error=false
      })
      .addCase(signup.fulfilled, (state, action:any) => {
        state.user = action.payload?.data;
        state.loading = false;
        state.message=action.payload?.message
        state.error=false
      })
      .addCase(signup.rejected, (state, action:any) => {
        state.loading = true;
        state.user = null;
        state.message=action.payload?.message
        state.error=true
      })
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.message=""
        state.error=false
      })
      .addCase(getUserById.fulfilled, (state, action:any) => {
        state.user = action.payload?.data;
        state.loading = false;
        state.message=action.payload?.message
        state.error=false
      })
      .addCase(getUserById.rejected, (state, action:any) => {
        state.loading = true;
        state.user = null;
        state.message=action.payload?.message
        state.error=true
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.message=""
        state.error=false
      })
      .addCase(changePassword.fulfilled, (state, action:any) => {
        // state. = action.payload?.data.id;
        state.loading = false;
        state.message=action.payload?.message
        state.error=false
      })
      .addCase(changePassword.rejected, (state, action:any) => {
        state.loading = true;
        state.user = null;
        state.message=action.payload
        state.error=true
      })
  },
});
export const { logout } =
userSlice.actions;

export default userSlice.reducer;
