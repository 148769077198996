import axiosInstanceChatbot from './axiosInstanceChatbot';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const nextQuestion = createAsyncThunk("NextQuestion", async (payload:NextQues, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceChatbot.post('/next-question',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const submitAnswer = createAsyncThunk("SubmitAnswer", async (payload: SubmitAns, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstanceChatbot.post('/submit-answer', payload);
      const data = response.data;
      if (data && data?.valid === true) {
         dispatch(nextQuestion(payload));
      }
      return data;
    } catch (error: any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  });

  export const startQuestions = createAsyncThunk("StartQuestions", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceChatbot.post('/restart',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });