import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { Interview_Tabs } from "src/utils/constant";
import { Radio } from "@material-tailwind/react";
interface propsData{
    handleOpen:()=>void
    open:boolean
    message?:string
    title?:string
    setActiveTab:(e?:any)=>void;
}
export function DialogCustomAnimation({handleOpen,open,setActiveTab,title}:propsData) {
const [selectedInterviewMode,setSelectedInterviewMode]= useState("")
const getRadioOptions = () => {
  return [Interview_Tabs.CHAT_WITH_BOT,Interview_Tabs.TALK_WITH_BOT,Interview_Tabs.QUESTIONNAIRE].map((ele: any) => { return { option: ele } });
};

const onSubmit=()=>{
  setActiveTab(selectedInterviewMode)
  handleOpen()
}
const onClose=()=>{
  setActiveTab(Interview_Tabs.ACCOUNTANT_CALL)
  handleOpen()
}

  return (
    <>
         <div className="mb-3 flex gap-3">
      <Dialog
        open={open}
        size={"xs"}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        
      >
        {title&&
        <DialogHeader className="text-md"> {title}</DialogHeader>
}
        <DialogBody>
      
          {getRadioOptions().map((opt: any) => (
                <Radio
                  label={opt.option}
                  className="w-6 h-6"
                  value={opt.option}
                  name={`interviewMode`}
                  checked={selectedInterviewMode === opt.option}
                  color={"blue"}
                  onChange={() => setSelectedInterviewMode(opt.option)}
                />
              ))}
        </DialogBody>
        <DialogFooter>
        <Button
            variant="text"
            color="blue"
            onClick={onSubmit}
            className="mr-1"
          >
            <span>Confirm</span>
          </Button>
          <Button
            variant="text"
            color="red"
            onClick={onClose}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
      
        </DialogFooter>
      </Dialog>
      </div>
    </>
  );
}