import React from "react";
import { Button } from "@material-tailwind/react";
import {
  ArrowRightIcon
} from "@heroicons/react/24/outline";
interface props{
  handleHideIntro: () => void;
  disableButton:boolean
}
const InitialPage = ({handleHideIntro,disableButton}:props) => {
  return (
    <div className="h-auto min-h-[70vh] flex flex-col md:flex-row ">
      <div className="flex-1 hidden md:block">
        <div className="welcome-info bg-[#EDEDED]  lg:w-1/2 md:w-1/2 sm:w-full p-4 pl-7 sm:!text-[14px] lg:!text-[18px] md:!text-[18px] ">
          <span className="flex  items-center pt-12 ">
            <span className="text-4xl whitespace-nowrap">
              {" "}
              Interview Assessment
            </span>
          </span>
        </div>
        <p className="pl-7 font-poppins text-lg font-semibold p-4 text-justify">
          To ensure a through and accurate review, we need to gather additional
          information about your business operations and the specifics of the
          claim.
        </p>
        <Button
          onClick={() => handleHideIntro()}
          style={{
            flex: 1,
            backgroundColor: "#207FBB",
            color: "#FFF",
        
          }}
          // disabled={disableButton}
          className="flex m-auto text-[14px] m-9"
        >
          Start <ArrowRightIcon color="#FFF" width={25} height={25} />
        </Button>
      
      </div>
      <div className="flex-1 block items-center content-center justify-center relative">
        <div className="vector_img"></div>
        {/* <div className="vector"></div> */}
      </div>
    </div>
  );
};

export default InitialPage;
