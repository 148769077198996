import PolicyGrid from "../modules/Documents/Policy Docs/PolicyGrid";
import PicturesGrid from "../modules/Documents/Pictures Docs/PicturesGrid";
import PayrollGrid from "../modules/Documents/Payroll Docs/PayrollGrid";
import InvoicesGrid from "../modules/Documents/Invoices Docs/InvoicesGrid";
import FinancialStatementGrid from "../modules/Documents/Financial Statements Docs/FinancialStatementGrid";
import {
  coverage,
  DemographicData,
  FinancialStatement,
  LossInformation,
} from "./mockData";
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface progresBarTabsProps {
  step: number;
  name: string;
  percentage: number;
  data?: any;
}
export enum Interview_Tabs {
  TALK_WITH_BOT = "Talk with a Bot",
  CHAT_WITH_BOT = "Chat with a Bot",
  ACCOUNTANT_CALL = "Talk to Our Accountant",
  QUESTIONNAIRE = "Fill Out The Questionnaire",
}
export const timeZones = [
  { name: "Pacific Standard Time", value: "Pacific Standard Time" },
  { name: "Eastern Standard Time", value: "Eastern Standard Time" },
  { name: "Central Standard Time", value: "Central Standard Time" },
  { name: "Mountain Standard Time", value: "Mountain Standard Time" },
];

export const steppertabs: Array<progresBarTabsProps> = [
  {
    step: 1,
    name: "Business Information",
    percentage: 0,
    data: DemographicData,
  },
  { step: 2, name: "Loss Information", percentage: 20, data: LossInformation },
  { step: 3, name: "Coverage", percentage: 40, data: coverage },
  // {
  //   step: 4,
  //   name: "Financial statements",
  //   percentage: 60,
  //   data: FinancialStatement,
  // },
  { step: 5, name: "Repair Timeline", percentage: 80 },
  { step: 6, name: "Review", percentage: 100 },
];

// for Dashboard Claims
export const STATUS_OPTIONS = [
  { name: "Planned", value: "Planned" },
  {
    name: "In Progress - Kick-off / Setup",
    value: "In Progress - Kick-off / Setup",
  },
  {
    name: "In Progress - Prep",
    value: "In Progress - Prep",
  },
  {
    name: "In Progress - Process",
    value: "In Progress - Process",
  },
  {
    name: "In Progress - Review",
    value: "In Progress - Review",
  },
  {
    name: "In Progress - Follow-up",
    value: "In Progress - Follow-up",
  },
  {
    name: "Waiting - Waiting for info",
    value: "Waiting - Waiting for info",
  },
  {
    name: "Waiting - Waiting for client",
    value: "Waiting - Waiting for client",
  },
  {
    name: "Waiting - Waiting for confirmation",
    value: "Waiting - Waiting for confirmation",
  },
  {
    name: "Completed - Cancelled",
    value: "Completed - Cancelled",
  },
];

export const documentsTabs = [
  {
    label: "Upload Policy",
    value: "upload_policy",
    component: PolicyGrid,
  },
  {
    label: "Upload Financial Statements",
    value: "upload_financial_statements",
    component: FinancialStatementGrid,
  },
  {
    label: "Upload Pictures",
    value: "upload_pictures",
    component: PicturesGrid,
  },
  {
    label: "Upload Payroll Docs",
    value: "upload_payroll_docs",
    component: PayrollGrid,
  },
  {
    label: "Upload Invoices",
    value: "upload_invoices",
    component: InvoicesGrid,
  },
];

export const ASSIGNEE_LIST = [
  // { name: "Joseph Scarlato", value: "joseph.scarlato@ampcusforensics.com" },
  { name: "Grant Mizel", value: "grant.mizel@ampcusforensics.com" },
  { name: "Priya Krishnamurthy", value: "priya.krishnamurthy@ampcusforensics.com" },
  // { name: "Vedant Jadhav", value: "vedant.jadhav@ampcustech.com" },
  // { name: "Loknath Prajapati", value: "loknath.prajapati@ampcustech.com" },
];
