import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./axiosInstance";

export const signup = createAsyncThunk("signUp", async (payload:User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/signUp',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const login = createAsyncThunk("login", async (payload:User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/login',payload);
      const data=response?.data
      if(data?.data?.id){
        localStorage.setItem("UserInfo",JSON.stringify(JSON.stringify(data.data)))
        
      }
      return response.data;
    } catch (error:any) {
        
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const changePassword = createAsyncThunk("changePassword", async (payload:User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/changePassword',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const getUserById = createAsyncThunk("getUserById", async (userId:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/auth/${userId}`);
      return response.data;
    } catch (error:any) {
        
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });