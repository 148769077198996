import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface Props {
  onChange: (e: any) => void;
  value: any;
  minDateTime?: Date | undefined;
  maxDateTime?: Date | undefined;
  className?: string;
  error?: boolean;
  errorText?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const DateTimePicker: React.FC<Props> = ({
  onChange,
  value,
  minDateTime,
  maxDateTime,
  className,
  error,
  errorText,
  onBlur,
}) => {
  const [dateValue, setDateValue] = useState<any>("");
  useEffect(() => {
    setDateValue(
      (value && value.length > 3
        ? value.replace("T", " ").replace(":00.32120", "")
        : "") || value
    );
  }, [value]);

  const handleChange = (e: any) => {
    setDateValue(e);
    onChange(e);
  };

  return (
    <div className="w-full">
      <DatePicker
        selected={dateValue}
        onChange={(date: any) => handleChange(date)} // Handle date and time change
        showTimeSelect
        dateFormat="yyyy-MM-dd h:mm aa" // Custom format for date and time
        timeIntervals={15} // 15-minute intervals
        placeholderText="Select date and time"
        minDate={
          minDateTime
            ? new Date(
                new Date(minDateTime).getFullYear(),
                new Date(minDateTime).getMonth(),
                new Date(minDateTime).getDate()
              ) // Extracts "YYYY-MM-DDTHH:MM" from ISO string
            : undefined
        }
        maxDate={
          maxDateTime
            ? new Date(
                new Date(maxDateTime).getFullYear(),
                new Date(maxDateTime).getMonth() + 1,
                new Date(maxDateTime).getDate()
              ) // Extracts "YYYY-MM-DDTHH:MM" from ISO string
            : undefined
        }
        className={`${
          className
            ? className
            : "border-2 text-sm !w-96 p-2 outline-blue-600 h-9 rounded-lg"
        } ${error && "!border-2 !border-red-600"}`}
      />

      {/* <input
   
        min={minDateTime
          ? minDateTime.toISOString().slice(0, 16)  // Extracts "YYYY-MM-DDTHH:MM" from ISO string
          : undefined}
        max={maxDateTime
          ? maxDateTime.toISOString().slice(0, 16)  // Extracts "YYYY-MM-DDTHH:MM" from ISO string
          : undefined}
        className={`${className
            ? className
            : "border-2 text-sm w-full p-2 outline-blue-600 h-9 rounded-lg"
          } ${error && "!border-2 !border-red-600"}`}
        type="datetime-local"
        value={dateValue}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={onBlur}
             step={"900"}
       
      />
      {errorText && errorText.length && (
        <div className="text-sm mt-[-1] text-red-600">{errorText}</div>
      )} */}
    </div>
  );
};

export default DateTimePicker;
