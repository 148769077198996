/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import TuneIcon from "@mui/icons-material/Tune";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import {
  resetClaims,
  updateAssignedAccountant,
  updateBusinessNumber,
  updateClaimNumber,
  updateClientName,
  updateFileNumber,
} from "../../store/slices/dashboardSlice";
import Filters from "./Filters";
import columnDefs from "./ColumnDefs";
import FormInput from "../../components/FormInput";
import CustomButton from "../../components/CustomButton";
import "./Dashboard.css";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getClaimList, PaginationSearchPayloadProps } from "src/api/claimApi";
import Loader from "src/components/loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const claimState = useAppSelector((state: RootState) => state.claim);
  const [searchKey, setSearchkey] = useState<string>("")
  const { filters } = useSelector(
    (state: RootState) => state.dashboardClaims
  );
  const {
    claimNumber,
    ampcusFileNumber,
    insuredBusinessName,
    assignedAccountant,
    clientName,
  } = filters;

  const [sortModel, setSortModel] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  useEffect(() => {
    const data: PaginationSearchPayloadProps = {
      pageNo: currentPage,
      pageSize: rowsPerPage,
      data: "",
    };
    if (searchKey === "") {
      dispatch(getClaimList(data));
    }
  }, [dispatch, searchKey, currentPage, rowsPerPage]);

  const _onSearch = () => {
    const data: PaginationSearchPayloadProps = {
      pageNo: currentPage,
      pageSize: rowsPerPage,
      data: searchKey,
    };
    dispatch(getClaimList(data));
  }

  const fetchSortedData = async (sortModel:any) => {
    const lSortBy=sortModel[0]?.field
    
    const payload = {
      "data": "",
      pageNo: currentPage,
      pageSize: rowsPerPage,
      "orderBy":sortModel[0]?.sort.toUpperCase()||"ASC",
      "sortBy": lSortBy==="clientName"?"clientDetailsFirstName": lSortBy,
      "searchClaimQuery": {
        "fileNumber": ampcusFileNumber,
        "claimNumber": claimNumber,
        "assignee": assignedAccountant,
        "insuredBusinessName": insuredBusinessName,
        "clientName": clientName
      }

    }
    dispatch(getClaimList(payload));
  };


  const handleSearch = useCallback(() => {
    const payload = {
      "data": "",
      pageNo: currentPage,
      pageSize: rowsPerPage,
      "orderBy":"",
      "sortBy":"",
      "searchClaimQuery": {
        "fileNumber": ampcusFileNumber,
        "claimNumber": claimNumber,
        "assignee": assignedAccountant,
        "insuredBusinessName": insuredBusinessName,
        "clientName": clientName
      }

    }
    dispatch(getClaimList(payload));
  }, [ampcusFileNumber, claimNumber, assignedAccountant, insuredBusinessName, clientName, dispatch]);

  const handleReset = () => {
    dispatch(resetClaims());
    _onSearch()
  };

  const handlePaginationChange = (paginationModel: GridPaginationModel) => {
    setRowsPerPage(paginationModel.pageSize);
    setCurrentPage(paginationModel.page);
  };

  const handleClaimNumberClick = (claim: any) => {
    if (!claim?.clientDetails) {
      toast.error("There is no client found agaisnt this claim.")
    } else {
      navigate(`/claim-details`, { state: { claim } });
    }
  };
  useEffect(() => {
    // Initial fetch on component mount or whenever sortModel changes
    fetchSortedData(sortModel);
  }, [sortModel]);
  return (
    <div>
      <div className="mb-5">
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-white text-black z-[9999999] transform ${isOpen ? "translate-x-0 box-sha" : "translate-x-full"
            } transition-transform duration-300`}
        >
          <Filters
            setIsOpen={setIsOpen}
            claimNumber={claimNumber}
            ampcusFileNumber={ampcusFileNumber}
            insuredBusinessName={insuredBusinessName}
            assignedAccountant={assignedAccountant}
            clientName={clientName}
            setClaimNumber={(value) => dispatch(updateClaimNumber(value))}
            setFileNumber={(value) => dispatch(updateFileNumber(value))}
            setBusinessNumber={(value) =>
              dispatch(updateBusinessNumber(value))
            }
            setAssignedAccountant={(value) =>
              dispatch(updateAssignedAccountant(value))
            }
            setClientName={(value) => dispatch(updateClientName(value))}
            onSearch={handleSearch}
            onReset={handleReset}
          />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col md:flex-row mb-5 gap-3 md:gap-5">
          <FormInput
            name="Search"
            placeholder="Search"
            type="text"
            className="w-full md:w-96 bg-white text-black-900 focus:border-2 focus:borer-t-[#212121] focus:shadow-lg focus:shadow-sky-100 placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900"
            value={searchKey}
            onChange={(e) => setSearchkey(e.target.value)}
          />
          <CustomButton
            className="w-full md:w-auto bg-[#045373] rounded-lg pl-6 pr-6 font-semibold"
            label="Search"
            onClick={_onSearch}
          />
        </div>
        <button
          className="bg-[#cfdce4] p-2 rounded-md -mt-4"
          onClick={() => setIsOpen(!isOpen)}
        >
          <TuneIcon className="mr-2" />
          Filters
        </button>
      </div>
      <div className="overflow-x-auto">
        <Box sx={{ height: 350, minHeight: 450, width: "100%" }}>
          {claimState?.loading ? (
            <Loader />
          ) : claimState?.error ? (
            <div>Error loading claims data</div>
          ) : (
            <DataGrid
              rows={claimState?.data?.data}
              columns={columnDefs(handleClaimNumberClick)}
              paginationModel={{
                page: currentPage,
                pageSize: rowsPerPage,
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              rowCount={claimState?.data?.totalRecords || 0}
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(model:any) => {
                setSortModel(model)
                // if(sortModel.length>0){
                //   setSortModel(model)
                // }
                // else if(sortModel.length>0){
                //    const sortDataArray=[...sortModel]
                //    const sortData:any=sortDataArray[0]
                //    sortData.sort=sortData.sort==="desc"?"asc":"desc"
                //   setSortModel([sortData])
                // }else{
                //   setSortModel(model)
                // }

              
              }}
              onPaginationModelChange={handlePaginationChange}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#cfdce4",
                },
                "& .MuiDataGrid-root": {
                  overflowX: "auto",
                },
              }}
            //   checkboxSelection
            />
          )}
        </Box>
      </div>
    </div>
  );
};

export default Dashboard;
