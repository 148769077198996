import {
    AlignmentType,
    Document,
    ImageRun,
    Header,
    Paragraph,
    Footer,
    TextRun,
    Table,
    TableRow,
    TableCell,
    WidthType,
    BorderStyle,
  } from "docx";
import logo from "../../../assets/images/final_logo_1.png"
import moment from "moment";
  export class DocumentCreator {
    // tslint:disable-next-line: typedef
    public async create([claimDetails,
      incidentType,
      salutation]:any): Promise<Document> {
      const response = await fetch(logo); // Use `/logo.png` if the image is in the public folder
      const arrayBuffer:any = await response.arrayBuffer();
      const imageUint8Array:any = new Uint8Array(arrayBuffer);

      // const responseLeft = await fetch(logo);
      // const arrayBufferLeft = await responseLeft.arrayBuffer();
      // const imageUint8ArrayLeft = new Uint8Array(arrayBufferLeft);
  
      // const responseRight = await fetch(logo);
      // const arrayBufferRight = await responseRight.arrayBuffer();
      // const imageUint8ArrayRight = new Uint8Array(arrayBufferRight);
  
      // Create the footer with a table for alignment
      const footer = new Footer({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  // Left image cell
                  new TableCell({
                    children: [
                      this.createHeading("Global Headquarters"),
                      this.createText("14900 Conference Center Dr. Suite 500"),
                      this.createText("Chantilly, VA 20151"),
                      this.createText("www.ampcusforensics.com | (703) 637-3633")
                    ],
                    borders: { // Remove borders for this cell
                      top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    },
                    width: { size: 50, type: WidthType.PERCENTAGE }, // Set the width of the cell
                  }),
                  // Right image cell
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          this.createBlueLine()
                        ],
                       
                        alignment: "right", // Align image to the right
                      }),
                      new Paragraph({
                        children: [
                          this.createBlueLine()
                        ],
                        alignment: "right", // Align image to the right
                      }),
                    ],
                    borders: { // Remove borders for this cell
                      top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                    },
                    width: { size: 50, type: WidthType.PERCENTAGE }, // Set the width of the cell
                  }),
                ],
              }),
            ],
            width: { size: 100, type: WidthType.PERCENTAGE }, // Set table width
          }),
        ],
      });
      const document = new Document({
        sections: [
          {
            headers: {
              default: new Header({
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: imageUint8Array, // Use Uint8Array here
                        transformation: {
                          width: 300, // Adjust width as needed
                          height: 100, // Adjust height as needed
                        },
                        type: "png", // Specify the image type
                      }),
                    ],
                    alignment: "center"
                  }),
                ],
              }),
            },
            footers: { default: footer },
            children: [
              new Paragraph({
                children:[
                  new TextRun({
                    text:`${moment(new Date()).format("MM/DD/YYYY")}`,
                    size: 24,
                  }),
                ],
               
                alignment: AlignmentType.LEFT,
                spacing: {
                  after: 200, // Adds space after the paragraph (in twips, 1/20 of a point)
                },
  
              }),
              new Paragraph({
                children:[
                  new TextRun({
                    text:`${salutation}${claimDetails?.clientDetails?.firstName}${""} ${claimDetails?.clientDetails?.lastName}`,
                    size: 24,
                  }),
                ],
                alignment: AlignmentType.LEFT
              }),
              new Paragraph({
                children:[
                  new TextRun({
                    text:`${claimDetails?.clientDetails?.salutation}`,
                    size: 24,
                  }),
                ],
                alignment: AlignmentType.LEFT
              }),
      
              new Paragraph({
                children:[
                  new TextRun({
                    text:`${claimDetails?.clientDetails?.emailAddress}`,
                 
                    size: 24,
                  }),
                ],

                alignment: AlignmentType.LEFT,
                spacing: {
                  after: 200, // Adds space after the paragraph (in twips, 1/20 of a point)
                },
  
              }),
              new Paragraph({
                children:[
                  new TextRun({
                    text:`${claimDetails?.clientDetails?.clientCompany}`,
                    size: 24,
                  }),
                ],
                alignment: AlignmentType.LEFT
              }),
              new Paragraph({
                children:[
                  new TextRun({
                    text: "RE: REPORT",
                    bold: true, // Make this part bold
                    size: 24,
                  }),
                ],
                alignment: AlignmentType.LEFT,
               
                spacing: {
                  after: 100, // Adds space after the paragraph (in twips, 1/20 of a point)
                },
              }),
              this.createInsuredInfo(claimDetails,incidentType),
              new Paragraph({
                children:[
                  new TextRun({
                    text:`${salutation}${claimDetails?.clientDetails?.firstName}${" "}${claimDetails?.clientDetails?.lastName},  `,
                    size: 24,
                  }),
                ],

                alignment: AlignmentType.LEFT,
                spacing: {
                  after: 200, // Adds space after the paragraph (in twips, 1/20 of a point)
                },
              }),
              new Paragraph({
                children:[
                  new TextRun({
                    text:
                    "At your request, we have analyzed the documentation furnished in support of the above captioned claim. Our analysis was limited to the books and records delivered and deemed pertinent in establishing the estimated loss. Because our analysis is not comprehensive in nature, it is subject to the accuracy of the information provided by the Insured and Claimant.  ",
                    size: 24,
                  
                  }),
               
                ],
                spacing: {
                  after: 100, // Adds space after the paragraph (in twips, 1/20 of a point)
                },
                alignment: AlignmentType.JUSTIFIED
              }),
              new Paragraph({
                children:[
                  new TextRun({
                    text:
                    "This report and any other information provided by AFI are furnished solely for discussion purposes and are subject to the interpretation of the insurance coverage, which does not necessarily conform to generally accepted accounting principles.  ",
                    size: 24,
                  })
                ],
               
                alignment: AlignmentType.JUSTIFIED
              })
            ]
          }
        ]
      });
  
      return document;
    }
  
   public createInsuredInfo(claimDetails:any,incidentType:any):Paragraph{

    return new Paragraph({
      alignment: AlignmentType.LEFT,
      indent: {
        left: 360, // Adds a left margin (360 twips = 18pt or 1/4 inch)
      },
      children: [
        new TextRun({
         text: `Insured: ${claimDetails?.insuredName} `,
         break:1,
         size: 24,
        }
        ),
        new TextRun({
          text: `Type of Incident: ${incidentType} `,
          break: 1,
          size: 24,
        }),
        new TextRun({
          text: `Date of Incident: ${moment(claimDetails?.dateOfLoss).format("YYYY-MM-DD")}`,
          break: 1,
          size: 24,
        }),
        new TextRun({
          text: `AFI File No: ${claimDetails?.fileNumber}`,
          break: 1,
          size: 24,
        }),
    
        new TextRun({
          style:"left-align",
          text: `Claim Number: ${claimDetails?.claimNumber}`,
          break: 1,
          size: 24,

        }),
       
      ],
      spacing: {
        after: 200, // Adds space after the paragraph (in twips, 1/20 of a point)
      },

    });
   }
     
   public createHeading(text: string): Paragraph {
    return new Paragraph({
      children:[
        new TextRun({
          text:text,
          size: 24,
          bold:true,
          color: "009BDB"
        }),
      ],
      spacing: {
        after: 100, // Adds space after the paragraph (in twips, 1/20 of a point)
      },
      // heading: HeadingLevel.HEADING_4,
    });
  }
  public createText(text: string): Paragraph {
    return new Paragraph({
      children:[
        new TextRun({
          text:text,
          size: 20,
          color: "000000"
        }),
      ],
    });
  }

  public createBlueLine(): Paragraph {
    return new Paragraph({
      border: {
        bottom: {
          style: "single", // Line style
          size: 48, // Thickness of the line (size in points)
          color: "009BDB", // Blue color for the line
        },
      },
    });
  }
  }
  